import React from 'react';
import useDataStore from '../../services/DataService';
import { Container, PageTitle, ExhibitionGrid, ExTitleContainer, ExTitle, ExImage, ExText, ExDateVenue } from './styles';

export default function Exhibitions() {

  const exhibitions = useDataStore(state => state.exhibitions);

  const renderExhibitions = () => exhibitions.map((e,i) =>
    <ExhibitionGrid key={i}>
      <ExTitleContainer>
        <ExTitle>{e.title}</ExTitle>
        <ExDateVenue>
          <div>{e.date}</div>
          <div>{e.venue}</div>
        </ExDateVenue>
      </ExTitleContainer>
      <ExImage src={e.image} />
      <ExText>{e.text}</ExText>
    </ExhibitionGrid>
  );

  return (
    <Container>
      <PageTitle>Exhibitions</PageTitle>
      {exhibitions && renderExhibitions()}
    </Container>
  );
}