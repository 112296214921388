import styled, { keyframes } from 'styled-components';
import { breakPoints, mediaMax } from '../../common/breakpoints';
import { colors } from '../../styles';

export const HeaderContainer = styled.div`
    display: inline-block;
    width: 100%;
    padding: 64px;
    box-sizing: border-box;
    color: ${colors.darkGray};
    ${mediaMax('small',`
      padding: 24px;
    `)}
    a {
      color: inherit;
      text-decoration: none;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MenuToggle = styled.div`
  animation: ${fadeIn} 0.3s linear forwards;
  float: right;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  float: right;
`;

const desktopFadeIn = keyframes`
  from {
    transform: translateX(5px);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`;

const mobileFadeIn = keyframes`
  from {
    transform: translateY(-5px);
    opacity: 0.5;
  }
  to {
    transform: none;
    opacity: 1;
  }
`;

export const MenuItem = styled.div`
  opacity: 0;
  animation: ${desktopFadeIn} 0.2s ease-out ${props => 0.15-props.index * 0.03}s forwards;
  cursor: pointer;
  &:hover{
    color: ${colors.darkGray};
  }
  float: left;
  transition: color .1s cubic-bezier(0.42, 0, 0.58, 1);
  text-align: right;

  margin: 0 15px 0 0;
  &:last-of-type{
    margin: 0;
    animation: none;
    opacity: 1;
  }

  a {
    text-decoration: none;
    color: ${props => props.light ? colors.lightGray : colors.darkGray};
  } 

  @media only screen and (max-width: ${breakPoints['small'].max}px) {
    float: none;
    margin: 0 0 4px 0 !important;
    animation: ${mobileFadeIn} 0.2s ease-out ${props => props.index * 0.03}s forwards !important;
    &:last-of-type{
      opacity: 0;
    }
    &:first-of-type{
      margin: 0;
      animation: none !important;
      opacity: 1;
    }
  }
`;