import styled from 'styled-components';
import { colors } from '../../styles';

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
`;

export const Input = styled.input`
  margin-left: 24px;
  display: inline-block;
  margin-left: 48px;
  width: 100px;
  border-width: 0 0 1px 0;
  border-color: ${colors.lightGray};
  &:focus{
    border-color: ${colors.darkGray};
    outline: none;
  }
`;
