import styled from 'styled-components';
import { mediaMax } from '../../common/breakpoints';


export const Container = styled.div`
  padding: 15px 64px 64px 64px;
  box-sizing: border-box;
  ${mediaMax('small',`
      padding: 24px 24px 24px 24px;
  `)}
`;