import styled, { createGlobalStyle, css } from 'styled-components';
import InterRegular from './assets/fonts/inter-v12-latin-regular.woff2';
import { mediaMax } from './common/breakpoints';

export const colors = {
  lightGray: '#bbb',
  darkGray: '#333',
};

export const absoluteFill = css`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    font-weight: normal;
    src: url('${InterRegular}') format('woff2');
  }
  body {
    margin: 0;
    font-size: 14px;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* ${mediaMax('small',`
      font-size: 12px;
    `)} */
  }
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    /* ${mediaMax('small',`
      font-size: 18px;
    `)} */
  }
`;

export const Startwork = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    width: 70%;
    cursor: pointer;
    ${mediaMax('small',`
      width: auto;
      height: 550px;
      position: relative;
      float: right;
    `)}
`;

export const StartworkVideo = styled.video`
    position: absolute;
    right: 0;
    top: 0;
    width: 70%;
    cursor: pointer;
    ${mediaMax('small',`
      width: auto;
      height: 550px;
      position: relative;
      float: right;
    `)}
`;

export const LoadingContainer = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

export const FullSize = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
