import create from '../utilities/zustand/create';
import axios from 'axios';

const REACT_APP_BASE_URL = 'https://backend.stephanhohenthanner.com'
const API_URL = REACT_APP_BASE_URL + '/api';
const xApiKey = 'PrQ8TWfEB5kDCjwEW92oe';

const useDataStore = create(module, (set, get) => ({
  loaded: false,
  startwork: undefined,
  startwork_video: undefined,
  start_show_video: undefined,
  accesspass: undefined,
  accessfile: undefined,
  gallery_link: undefined,
  artworks: undefined,
  exhibitions: undefined,
  cv: undefined,
  contact: undefined,
  getAll: () => {
    axios({
      headers: {
        'x-api-key': xApiKey,
      },
      method: 'get',
      url: API_URL + '/getAll',
      }).then(res => {
        set({
          loaded: true,

          startwork: res.data.artworks.startwork,
          startwork_video: res.data.artworks.startwork_video,
          start_show_video: res.data.artworks.start_show_video,
          start_tags: res.data.artworks.start_tags,
          artworks: formatArtworks(res.data.artworks.artworks),

          exhibitions: res.data.exhibitions.exhibitions,

          cv: res.data.infoBlocks.cv,
          contact: res.data.infoBlocks.contact,

          accesspass: res.data.rest.access_pass,
          accessfile: res.data.rest.access_file,
          gallery_link: res.data.rest.gallery_link
        });
      });
  },
  getArtworkById: id => {
    const { artworks } = get();
    const showArtwork = artworks.find(a => a.id === id);
    return showArtwork;
  },
}));

const formatArtworks = (artworks) => {
  const newArtworks = [...artworks];
  for (let a = 0; a < newArtworks.length; a++) {
    newArtworks[a].tags = newArtworks[a].tags.split(' ');
  }
  return newArtworks;
}

export default useDataStore;
