import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Header from './components/Header';
import AppContent from './components/AppContent';
import useDataStore from './services/DataService';
import { Startwork, FullSize, StartworkVideo, LoadingContainer } from './styles';
import { useWindowStore } from './services/WindowService';

function App() {
  const loaded = useDataStore(state => state.loaded);
  const startwork = useDataStore(state => state.startwork);
  const startwork_video = useDataStore(state => state.startwork_video);
  const start_show_video = useDataStore(state => state.start_show_video);
  const start_tags = useDataStore(state => state.start_tags);

  useEffect(()=>{
    document.title = "Stephan Hohenthanner";
    useDataStore.getState().getAll();
    useWindowStore.getState().init();
  }, [])

  const workLink = '/work?' + start_tags;

  return (
    <Router>
      <div className="App">
        {!loaded && <LoadingContainer>Loading</LoadingContainer>}
        {loaded && 
          <Routes>
            <Route path="/:page" element={<><Header /><AppContent /></>}/>
            <Route path="/" element={
              <>
                <Link to={workLink}>
                  <FullSize>
                    <Header simple />
                    {start_show_video ? <StartworkVideo src={startwork_video} autoPlay loop muted /> : <Startwork src={startwork} />}
                  </FullSize>
                </Link>
              </>
            }/>
          </Routes>
        }
      </div>
    </Router>
  );
}

export default App;
