import React from 'react';
import useDataStore from '../../services/DataService';
import useNavigationStore from '../../services/NavigationService';
import InfoPage from '../InfoPage';
import Exhibitions from '../Exhibitions';
import Works from '../Works';
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import Access from '../Access';

export default function AppContent() {

  const cv = useDataStore(state => state.cv);
  const contact = useDataStore(state => state.contact);

  const { page } = useParams();

  return (
    <Container onClick={() => {useNavigationStore.getState().setMenuOpen(false)}}>
      {page === 'work' && <Works />}
      {page === 'exhibitions' && <Exhibitions />}
      {page === 'cv' && cv && <InfoPage blocks={cv} />}
      {page === 'contact'&& contact && <InfoPage blocks={contact} />}
      {page === 'access'&& contact && <Access />}
    </Container>
  );
}