import React, { useState } from 'react';
import useDataStore from '../../services/DataService';
import { Container, Input } from './styles';

export default function Access() {

  const [input, setInput] = useState('');
  const accesspass = useDataStore(state => state.accesspass);
  const accessfile = useDataStore(state => state.accessfile);

  const onChange = value => {
    setInput(value);
    if(value === accesspass) {
      var link = document.createElement("a");
      link.setAttribute('download', '');
      link.setAttribute('target', '_blank');
      link.href = accessfile;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <Container>
      <span>Password</span>
      <Input type="password" value={input} onChange={event => {onChange(event.target.value)}} />
    </Container>
  );
}