import create from '../utilities/zustand/create';
import { breakPoints } from '../common/breakpoints';
import debounce from '../utilities/debounce';
import { prefixEventListener } from '../utilities/prefixed-events';

export const useWindowStore = create(module, set => ({
  isMobile: false,

  init: () => {
    const onResize = () => {
      const width = window.innerWidth;
      set({ isMobile: width <= breakPoints.small.max });
    };

    prefixEventListener(window, 'resize', debounce(onResize, 250));
    onResize();
  },
}));
