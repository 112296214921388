import create from '../utilities/zustand/create';

const useNavigationStore = create(module, (set, get) => ({
  showArtwork: undefined,
  menuOpen: false,
  setMenuOpen: menuOpen => {
    set({menuOpen});
  },
  setShowArtwork: artwork => {
    set({showArtwork: artwork});
  },
}));

export default useNavigationStore;
