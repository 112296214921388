import React from 'react';
import useDataStore from '../../services/DataService';
import { Container, InfoblockGrid, Title, Subtitle, Info, Link } from './styles';

export default function InfoPage({blocks}) {

  const galleryLink = useDataStore(state => state.gallery_link);

  const renderBlocks = () => blocks.map((b, i) =>
    <InfoblockGrid key={i}>
      <div><Title>{b.title}</Title></div>
      {
        b.subinfos.map((si, i2) =>
        <React.Fragment key={i2}>
          {si.subtitle !== "" && <Subtitle>{findLinks(si.subtitle, galleryLink)}</Subtitle>}
          <Info dangerouslySetInnerHTML={{ __html: si.info}} fullWidth={si.subtitle === ""} />
        </React.Fragment>)
      }
    </InfoblockGrid>);

  return (
    <Container>
      {blocks && renderBlocks()}
    </Container>
  );
}

const findLinks = (txt, galleryLink) => {
  if (txt === '!!!socialmedia') {
    return <>
      <Link href='mailto:contact@stephanhohenthanner.com'>Mail</Link><br />
      <Link href='https://www.instagram.com/stephanhohenthanner/' target="_blank" rel="noreferrer">Instagram</Link>
    </>;
  } else if (txt === '!!!gallerywebsite') {
    return <Link href={galleryLink} target="_blank" rel="noreferrer">Website</Link>;
  } else if (txt === '-') {
    return " ";
  } else {
    return txt;
  }
};