import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../common/breakpoints';
import { colors } from '../../styles';

export const Container = styled.div`
`;

export const Title = styled.h1`
  position: absolute;
  ${mediaMax('small',`
    position: inherit;
  `)}
`;

export const Subtitle = styled.div`
  text-align: right;
  grid-column: 2/3;
  ${mediaMax('small',`
    text-align: left;
    grid-column: 1/2;
  `)}
`;

export const Info = styled.div`
  grid-column: ${props => props.fullWidth ? '1/3' : '2/3'};
  /* grid-column: 3/4; */
  ${mediaMin('medium',`
    grid-column: 3/4;
  `)}
  /* br {
    content: "";
    display: block;
    margin-bottom: 32px;
    ${mediaMax('small',`
      margin-bottom: 24px;
    `)}
  } */
`;

export const InfoblockGrid = styled.article`
  display: grid;
  grid-template-columns: 200px 100px 1fr;
  grid-gap: 32px 96px;
  align-items: start;
  margin-bottom: 48px;
  ${mediaMax('small',`
    grid-template-columns: 50px 1fr;
    grid-gap: 24px 32px;
  `)}
  ${mediaMin('large',`
    grid-template-columns: repeat(4, 1fr);
  `)}
`;

export const Link = styled.a`
  color: ${colors.darkGray};
`;
