import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useDataStore from '../../services/DataService';
import useNavigationStore from '../../services/NavigationService';
import { HeaderContainer, MenuToggle, MenuContainer, MenuItem } from './styles';

export default function Header({simple = false}) {
  const { page } = useParams();
  const menuOpen = useNavigationStore(state => state.menuOpen);
  const start_tags = useDataStore(state => state.start_tags);

  if (simple) {
    return (
      <HeaderContainer>
        Stephan Hohenthanner
      </HeaderContainer>
    );
  }

  const workLink = '/work?' + start_tags;

  const links = {
    'Work' : workLink,
    'Exhibitions' : '/exhibitions',
    'CV' : '/cv',
    'Access' : '/access',
    'Contact' : '/contact'
  };

  const renderMenu = ()=> {
    return (
      <MenuContainer>
        {['Work','Exhibitions','CV','Access','Contact'].map((n, i) =>
            <MenuItem light={page !== n.toLowerCase()} index={i} key={i}>
              <Link to={links[n]}>
                {n}
              </Link>
            </MenuItem>
        )}
      </MenuContainer>
    );
  };

  return (
    <HeaderContainer onMouseLeave={()=>{useNavigationStore.getState().setMenuOpen(false)}}>
      <Link to='/'>Stephan Hohenthanner</Link>
      {page !== undefined && (
        menuOpen ? renderMenu() : <MenuToggle onClick={()=>{useNavigationStore.getState().setMenuOpen(true)}}>Open</MenuToggle>
      )}
    </HeaderContainer>
  );
}
