import styled from 'styled-components';
import { mediaMax, mediaMinMax } from '../../common/breakpoints';

export const Container = styled.div`
`;

export const PageTitle = styled.div`
  margin-bottom: 32px;
  ${mediaMax('small',`
    margin-bottom: 14px;
  `)}
`;

export const ExTitleContainer = styled.div`
  margin-bottom: 24px;
  ${mediaMinMax('medium', 'medium',`
    width: 50%;
    float: left;
  `)}
`;

export const ExTitle = styled.h1`
  margin-bottom: 24px;
  ${mediaMax('small',`
    margin-bottom: 8px;
  `)}
`;

export const ExDateVenue = styled.div`
`;

export const ExImage = styled.img`
  grid-column: 2/4;
  width: 100%;
  ${mediaMinMax('medium', 'medium',`
    float: right;
    width: calc(50% - 32px);
  `)}
  ${mediaMax('small',`
    margin-bottom: 24px;
  `)}
`;

export const ExText = styled.div`
  column-count: 1;
  ${mediaMinMax('medium', 'medium',`
    float: left;
    width: calc(50% - 32px);
  `)}
  ${mediaMinMax('small', 'small',`
    column-count: 2;
  `)}
`;

export const ExhibitionGrid = styled.article`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 96px 96px;
  align-items: start;
  margin-bottom: 80px;
  ${mediaMinMax('medium', 'medium',`
    display: inline-block;
  `)}
  ${mediaMax('small',`
    display: inline-block;
  `)}
`;
