import React, { useRef, useState } from 'react';
import ReactSwipe from 'react-swipe';
import { useWindowStore } from '../../services/WindowService';
import { Container, Img, ImgContainer, SliderContainer, Arrow, Pagination, PaginationDot, ClickBox, SliderImg, ArrowLeftVector, ArrowRightVector, SlideContainer } from './styles';

export default function Work({work}) {
  const [imageIndex, setImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const isMobile = useWindowStore(state => state.isMobile);
  const reactSwipeEl = useRef();
  
  const nextImg = () => { setImageIndex((imageIndex + 1) % work.images.length) };
  const prevImg = () => { setImageIndex((work.images.length + imageIndex - 1) % work.images.length) };
  
  const onSlideChange = () => {
    setImageIndex(reactSwipeEl.current.getPos());
  };

  const onPaginationClick = index => {
    if(isMobile) reactSwipeEl.current.slide(index, 500);
    setImageIndex(index);
  }

  const swipeOptions = useRef({transitionEnd: onSlideChange, continuous: false});

  return (
    <Container>
        {
          isMobile ? (
            <div style={{overflow: 'hidden'}}>
              <SliderContainer>
                <ReactSwipe swipeOptions={swipeOptions.current} ref={reactSwipeEl} key={work.id}>
                  {work.images.map((img, index) => <SlideContainer key={index}><SliderImg src={img} onLoadCapture={()=>{setImageLoaded(true)}} /></SlideContainer>)}
                </ReactSwipe>
              </SliderContainer>
            </div>
          ) : (
            <ImgContainer>
              <Img src={work.images[imageIndex]} alt="Kunstwerk" onLoadCapture={()=>{setImageLoaded(true)}} />
              {work.images.length > 1 && (
                <>
                  <ClickBox onClick={prevImg}>
                    {!isMobile && <Arrow>{ArrowLeftVector()}</Arrow>}
                  </ClickBox>
                  <ClickBox right onClick={nextImg}>
                    {!isMobile && <Arrow right>{ArrowRightVector()}</Arrow>}
                  </ClickBox>
                </>
              )}
            </ImgContainer>
          )
        }
      
      {work.images.length > 1 && imageLoaded && (
        <Pagination>
          {work.images.map((img, index) => <PaginationDot key={index} active={index === imageIndex} onClick={() => {onPaginationClick(index)}} />)}
        </Pagination>
      )}
      {imageLoaded && (
        <p style={{marginTop: 15}}>
          {work.title_de}, {work.year} <br />
          {work.medium_de}<br />
          {work.height} x {work.width} cm
        </p>
      )}
    </Container>
  );
}